import React, { useState, useMemo, useCallback, useEffect } from 'react'
import NumberFormat from './NumberFormat'
import './style.css'

interface PieChartData {
    label: string
    value: number
}
const colors = ['#3C18BC', '#649A3B', '#563AF5', '#4E9AFF']

interface PlanChartProps {
    plan_guid: string
    initialIndex: number
    totalIndex?: number
}

const PieChartPlanSumm: React.FC<PlanChartProps> = ({
    plan_guid,
    initialIndex,
    totalIndex,
}) => {
    const [pieData, setPieData] = useState<PieChartData[]>([])
    const [selectedLabel, setSelectedLabel] = useState<string>('')
    const [isAnimated, setIsAnimated] = useState<boolean>(false)
    const [isFirstAnimationDone, setIsFirstAnimationDone] =
        useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [infoTooltip, setInfoTooltip] = useState<{
        x: number
        y: number
    } | null>(null)

    useEffect(() => {
        if (plan_guid === 'Нет данных') {
            return
        }
        // Fetch data from the API
        fetch(
            `https://itzakupki.ru/api/backend/v1/plan_positions_summ_piechartplansumm/?plan_guid=${plan_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                // Map data from the API response to pieData format
                const mappedData: PieChartData[] = [
                    { label: 'Всего закупок', value: data.poses_summ },
                    {
                        label: 'Активные закупки',
                        value: data.published_poses_summ,
                    },
                    {
                        label: 'Переходящие закупки',
                        value: data.shared_poses_summ,
                    },
                    {
                        label: 'Аннулированные закупки',
                        value: data.annulled_poses_summ,
                    },
                ]
                setPieData(mappedData)
                setSelectedLabel(
                    mappedData[
                        initialIndex >= 0 && initialIndex < mappedData.length
                            ? initialIndex
                            : 0
                    ].label
                )

                // Set timeouts after data has been loaded
                setTimeout(() => {
                    setIsAnimated(true)
                }, 100)
                setTimeout(() => {
                    setIsFirstAnimationDone(true)
                }, 1100)
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [initialIndex, plan_guid])

    const hasTotal =
        typeof totalIndex === 'number' &&
        totalIndex >= 0 &&
        totalIndex < pieData.length
    const validTotalIndex = hasTotal ? totalIndex : null

    const total =
        validTotalIndex !== null
            ? pieData[validTotalIndex].value
            : pieData.reduce((sum, data) => sum + data.value, 0)
    const radius = 15.9
    const circumference = 2 * Math.PI * radius

    const handlePieDataSelection = useCallback(
        (label: string) => {
            if (label !== selectedLabel) {
                setSelectedLabel(label)
            }
        },
        [selectedLabel]
    )

    const isTotalSelected = useMemo(
        () =>
            validTotalIndex !== null &&
            pieData[validTotalIndex]?.label === selectedLabel,
        [selectedLabel, validTotalIndex, pieData]
    )

    let offset = 0
    let delay = 0

    const segments = pieData
        .filter((_, index) => index !== validTotalIndex)
        .map((data, index) => {
            const percentage = (data.value / total) * 100
            const segmentLength = (percentage / 100) * circumference
            const isSelected = data.label === selectedLabel
            const color =
                validTotalIndex !== null
                    ? colors[(index + 1) % colors.length]
                    : colors[index % colors.length]
            const segment = (
                <React.Fragment key={data.label}>
                    <circle
                        cx="18"
                        cy="18"
                        r={radius}
                        fill="transparent"
                        stroke={color}
                        strokeWidth={isSelected ? 4 : 2}
                        strokeDasharray={`${isAnimated ? segmentLength : 0} ${circumference}`}
                        strokeDashoffset={-offset}
                        transform="rotate(0 18 18)"
                        style={{
                            transition: `stroke-dasharray ${Math.round(percentage) / 100}s linear ${delay}s`,
                        }}
                    />
                    {isFirstAnimationDone && (
                        <text
                            x="18"
                            y="20"
                            textAnchor="middle"
                            fontSize="4"
                            fill={isSelected ? '#FFF' : 'transparent'}
                            transform="rotate(90 18 18)"
                        >
                            {Math.round(percentage * 100) / 100} %
                        </text>
                    )}
                </React.Fragment>
            )

            delay += Math.round(percentage) / 100
            offset += segmentLength
            return segment
        })

    return (
        <div className="flex flex-col w-full h-full space-y-5">
            <div className="flex flex-row text-white text-[16px]">
                <div className="flex flex-1">План текущего года (руб.)</div>
                <div
                    onClick={(e) =>
                        setInfoTooltip({
                            x: e.clientX,
                            y: e.clientY,
                        })
                    }
                    onMouseLeave={() => setInfoTooltip(null)}
                    style={{ cursor: 'pointer' }}
                >
                    <button className="w-5 h-5">
                        <svg
                            id="info-circle"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#FFF"
                                d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            {plan_guid == 'Нет данных' ? (
                <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                    Нет данных...
                </div>
            ) : (
                <>
                    {isLoading ? (
                        <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                            Загрузка данных...
                        </div>
                    ) : (
                        <>
                            <div className="flex flex-row space-x-5 justify-start">
                                <div className="flex items-center">
                                    <svg
                                        viewBox="0 0 36 36"
                                        className="pie-chart"
                                        aria-label="Диаграмма закупок"
                                        aria-live="polite"
                                    >
                                        {/* Сегмент диаграммы */}
                                        {segments}
                                        {/* Total Segment сегмент диаграммы означающий всего */}
                                        {isTotalSelected &&
                                            validTotalIndex !== null && (
                                                <React.Fragment key="total-segment">
                                                    {/* Добавляем ключ для Total Segment */}
                                                    <circle
                                                        r="15.9"
                                                        cx="18"
                                                        cy="18"
                                                        fill="transparent"
                                                        stroke={
                                                            colors[
                                                                validTotalIndex %
                                                                    colors.length
                                                            ]
                                                        }
                                                        strokeWidth={4}
                                                        strokeDasharray={`100 0`}
                                                        strokeDashoffset={0}
                                                        style={{
                                                            transition:
                                                                'stroke-dasharray 0.5s ease, stroke 0.5s ease',
                                                        }}
                                                    />
                                                    {isFirstAnimationDone && (
                                                        <text
                                                            x="18"
                                                            y="20"
                                                            textAnchor="middle"
                                                            fontSize="4"
                                                            fill="#FFF"
                                                            transform="rotate(90 18 18)"
                                                        >
                                                            100 %
                                                        </text>
                                                    )}
                                                </React.Fragment>
                                            )}
                                    </svg>
                                </div>
                                <div className="flex items-center">
                                    <ul className="flex flex-col items-start space-y-1">
                                        {pieData.map((item, index) => (
                                            <li
                                                key={item.label}
                                                style={{
                                                    color:
                                                        selectedLabel ===
                                                        item.label
                                                            ? '#FFF'
                                                            : '#848484',
                                                }}
                                                className="flex flex-row items-center space-x-2 text-[12.8px]"
                                            >
                                                <svg width="24" height="24">
                                                    {selectedLabel ===
                                                        item.label && (
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="none"
                                                            stroke={
                                                                colors[
                                                                    index %
                                                                        colors.length
                                                                ] + '55'
                                                            }
                                                            strokeWidth="5"
                                                        />
                                                    )}
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="6"
                                                        fill={
                                                            colors[
                                                                index %
                                                                    colors.length
                                                            ]
                                                        }
                                                    />
                                                </svg>
                                                <button
                                                    aria-label={`Выбрать ${item.label}`}
                                                    onClick={() =>
                                                        handlePieDataSelection(
                                                            item.label
                                                        )
                                                    }
                                                >
                                                    <div className="text-left">
                                                        {item.label}
                                                    </div>
                                                    <div className="text-left">
                                                        <NumberFormat
                                                            value={item.value}
                                                        />{' '}
                                                        ₽
                                                    </div>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            {/* infoTooltip */}
            {infoTooltip && (
                <div
                    className="flex flex-col w-[300px] p-2"
                    style={{
                        position: 'fixed',
                        left: infoTooltip.x - 315,
                        top: infoTooltip.y - 30,
                        backgroundColor: '#464447',
                        border: '1px solid gray',
                        borderRadius: '5px',
                        color: '#FFF',
                        fontSize: 12.8,
                    }}
                >
                    Это график количества закупок по месяцам.
                </div>
            )}
        </div>
    )
}

export default PieChartPlanSumm
