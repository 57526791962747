import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { PlanPosition } from '../types'

import PurchaseBasicInfo from './components/PurchaseBasicInfo'
import PurchaseBudgetFunds from './components/PurchaseBudgetFunds'
import PurchaseDatesInfo from './components/PurchaseDatesInfo'
import PurchaseItemsSection from './components/PurchaseItemsSection'
import PurchaseMethod from './components/PurchaseMethod'
import PurchasePaymentBreakdown from './components/PurchasePaymentBreakdown'

import Footer from './Footer'
import PurchaseChangeInfo from './components/PurchaseChangeInfo'

export const loadPlanPosition = async ({
    params,
}: any): Promise<PlanPosition[]> => {
    return fetch(
        'https://itzakupki.ru/api/backend/v1/plan_positions/?plan_guid=' +
            encodeURIComponent(params.plan_guid) +
            '&guid=' +
            encodeURIComponent(params.pos_guid),
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch plan positions')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

function yearDiff(date1: Date, date2: Date): number {
    return Math.abs(date1.getFullYear() - date2.getFullYear())
}

const PlanPositionEditPage: React.FC = () => {
    const data = useLoaderData() as PlanPosition[]
    const [pos, setPos] = useState(data[0])

    const handleSave = () => {
        // okato и purchase_period_month должны быть string
        Object.defineProperty(pos, 'okato', { value: '98000000000' })
        Object.defineProperty(pos, 'purchase_period_month', { value: '8' })

        fetch(
            'https://itzakupki.ru/api/backend/v1/plan_position_draft/?plan_guid=' +
                encodeURIComponent(pos.plan_guid) +
                '&guid=' +
                encodeURIComponent(pos.guid),
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pos),
            }
        )
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Неверный почтовый адрес или пароль')
                }
            })
            .catch((error) => undefined)
            .finally(() => undefined)
    }

    const [nmcd, setNmcd] = useState(1)
    const [isNmcdWithTax, setNmcdWithTax] = useState(true)

    const [currency, setCurrency] = useState('rub')
    const [currencyDate, setCurrencyDate] = useState<Date | undefined>()
    const [currencyRate, setCurrencyRate] = useState<number | undefined>()

    useEffect(() => {
        if (currency === 'rub') {
            setCurrencyDate(undefined)
            setCurrencyRate(undefined)
        } else {
            setCurrencyDate(new Date())
            setCurrencyRate(1)
        }
    }, [currency])

    const [isBudgetFundsIncluded, setBudgetFundsIncluded] = useState(false)

    const [purchaseStartDate, setPurchaseStartDate] = useState<Date>(new Date())
    const [purchaseEndDate, setPurchaseEndDate] = useState<Date>(new Date())
    const [isStartPreciseDate, setIsStartPreciseDate] = useState<boolean>(true)

    const [purchaseMethod, setPurchaseMethod] = useState('')
    const [basisSingleSupplier, setBasisSingleSupplier] = useState('')
    const [areParticipantsSME, setAreParticipantsSME] = useState(false)
    const [isPlannedThirdYear, setIsPlannedThirdYear] = useState(false)
    const [isInnovativeProduct, setIsInnovativeProduct] = useState(false)
    const [isExcludedForSME, setIsExcludedForSME] = useState(false)
    const [purchaseCategory, setPurchaseCategory] = useState('')

    const [yearlyPayments, setYearlyPayments] = useState(
        new Map<number /* year */, number>()
    )

    const [smeYearlyPayments, setSmeYearlyPayments] = useState(
        new Map<number /* year */, number>()
    )

    useEffect(() => {
        const newYearlyPayments = new Map<number, number>()
        const newSmeYearlyPayments = new Map<number, number>()

        const startYear = purchaseStartDate.getFullYear()
        const endYear = purchaseEndDate.getFullYear()
        for (let year = startYear; year <= endYear; year++) {
            newYearlyPayments.set(year, yearlyPayments.get(year) || 0)
            newSmeYearlyPayments.set(year, smeYearlyPayments.get(year) || 0)
        }

        setYearlyPayments(newYearlyPayments)
        setSmeYearlyPayments(newSmeYearlyPayments)
    }, [purchaseStartDate, purchaseEndDate])

    useEffect(() => {
        if (purchaseMethod === 'single_supplier') {
            setBasisSingleSupplier('todo')
        } else {
            setBasisSingleSupplier('')
        }
    }, [purchaseMethod])

    useEffect(() => {
        if (isExcludedForSME) {
            setPurchaseCategory('todo')
        } else {
            setPurchaseCategory('')
        }
    }, [isExcludedForSME])

    return (
        <div className="h-full flex flex-col">
            <header className="p-5">header</header>

            <main className="grow overflow-y-auto flex flex-col gap-y-5 p-5">
                {pos.status !== 'N' && <PurchaseChangeInfo />}

                <PurchaseBasicInfo
                    name={pos.contract_subject}
                    onNameChange={(name) => {
                        setPos((prev) => ({ ...prev, contract_subject: name }))
                    }}
                    minRequirements={pos.minimum_requirements || ''}
                    onMinRequirementsChange={(minRequirements) => {
                        setPos((prev) => ({
                            ...prev,
                            minimum_requirements: minRequirements,
                        }))
                    }}
                    contractPriceReason={pos.order_pricing || ''}
                    onContractPriceReasonChange={(contractPriceReason) => {
                        setPos((prev) => ({
                            ...prev,
                            order_pricing: contractPriceReason,
                        }))
                    }}
                    nmcd={nmcd}
                    onNmcdChange={setNmcd}
                    currency={currency}
                    onCurrencyChange={setCurrency}
                    isNmcdWithTax={isNmcdWithTax}
                    onNmcdWithTaxChange={setNmcdWithTax}
                    currencyExchangeDate={currencyDate}
                    onCurrencyExchangeDateChange={setCurrencyDate}
                    currencyExchangeRate={currencyRate}
                    onCurrencyExchangeRateChange={setCurrencyRate}
                />
                <PurchaseBudgetFunds
                    isBudgetFundsIncluded={isBudgetFundsIncluded}
                    onBudgetFundsIncludedChange={setBudgetFundsIncluded}
                />
                <PurchaseDatesInfo
                    start={purchaseStartDate}
                    onStartDateChange={setPurchaseStartDate}
                    end={purchaseEndDate}
                    onEndDateChange={setPurchaseEndDate}
                    isStartPreciseDate={isStartPreciseDate}
                    onIsStartPreciseDateChange={setIsStartPreciseDate}
                />

                {yearDiff(purchaseStartDate, purchaseEndDate) > 0 && (
                    <div className="flex flex-row gap-x-4">
                        <div className="basis-1/2">
                            <PurchasePaymentBreakdown
                                title="Информация об объемах оплаты долгосрочного договора"
                                yearlyPayments={yearlyPayments}
                                onYearlyPaymentsChange={(year, value) => {
                                    const newYearlyPayments = new Map(
                                        yearlyPayments
                                    )
                                    newYearlyPayments.set(year, value)
                                    setYearlyPayments(newYearlyPayments)
                                }}
                                currency={currency}
                                currencyExchangeDate={currencyDate}
                                currencyExchangeRate={currencyRate}
                            />
                        </div>
                        <div className="basis-1/2">
                            <PurchasePaymentBreakdown
                                title="Информация об объемах привлечения субъектов малого и среднего предпринимательства"
                                yearlyPayments={smeYearlyPayments}
                                onYearlyPaymentsChange={(year, value) => {
                                    const newSmeYearlyPayments = new Map(
                                        smeYearlyPayments
                                    )
                                    newSmeYearlyPayments.set(year, value)
                                    setSmeYearlyPayments(newSmeYearlyPayments)
                                }}
                                currency={currency}
                                currencyExchangeDate={currencyDate}
                                currencyExchangeRate={currencyRate}
                            />
                        </div>
                    </div>
                )}

                <PurchaseMethod
                    method={purchaseMethod}
                    onMethodChange={setPurchaseMethod}
                    basisSingleSupplier={basisSingleSupplier}
                    onBasisSingleSupplierChange={setBasisSingleSupplier}
                    areParticipantsSME={areParticipantsSME}
                    onAreParticipantsSMEChange={setAreParticipantsSME}
                    isPlannedThirdYear={isPlannedThirdYear}
                    onIsPlannedThirdYearChange={setIsPlannedThirdYear}
                    isInnovativeProduct={isInnovativeProduct}
                    onIsInnovativeProductChange={setIsInnovativeProduct}
                    isExcludedForSME={isExcludedForSME}
                    onIsExcludedForSMEChange={setIsExcludedForSME}
                    category={purchaseCategory}
                    onCategoryChange={setPurchaseCategory}
                />

                <PurchaseItemsSection planGuid={pos.plan_guid} />
            </main>

            <Footer handleSave={handleSave} />
        </div>
    )
}

export default PlanPositionEditPage
