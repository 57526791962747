import React from 'react'

const OrgHomePage: React.FC = () => {
    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Настройки организации
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_120px)] overflow-y-auto py-5">
                <div className="flex flex-col h-max w-[800px] space-y-10">
                    {/* Моя организация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Моя организация</p>
                        </div>

                        <div className="flex flex-row space-x-5">
                            <div className="flex items-start">
                                <div className="h-[100px] w-[100px] grid bg-[#181818] rounded-full place-items-center">
                                    <div className="flex flex-col items-center">
                                        <button className="w-6 h-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="image-plus"
                                            >
                                                <path
                                                    fill="#8A8A8A"
                                                    d="M19,10a1,1,0,0,0-1,1v3.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.71L9.41,11.12a2.79,2.79,0,0,0-3.93,0L4,12.61V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19.22A2.79,2.79,0,0,0,4.78,22H17.22a2.88,2.88,0,0,0,.8-.12h0a2.74,2.74,0,0,0,2-2.65V11A1,1,0,0,0,19,10ZM5,20a1,1,0,0,1-1-1V15.43l2.89-2.89a.78.78,0,0,1,1.1,0L15.46,20Zm13-1a1,1,0,0,1-.18.54L13.3,15l.71-.7a.77.77,0,0,1,1.1,0L18,17.21ZM21,4H20V3a1,1,0,0,0-2,0V4H17a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2Z"
                                                ></path>
                                            </svg>
                                        </button>
                                        <p className="text-[#8A8A8A] text-xs">
                                            Загрузить
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full space-y-3">
                                {/* Полное наименование */}
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Полное наименование{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                {/* Сокращенное наименование, Фирменное наименование */}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Сокращенное наименование{' '}
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                style={{ colorScheme: 'dark' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Фирменное наименование
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                style={{ colorScheme: 'dark' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* ИНН, КПП, Дата постановки на учет*/}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                ИНН{' '}
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                style={{ colorScheme: 'dark' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                КПП{' '}
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                style={{ colorScheme: 'dark' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Дата постановки на учет{' '}
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="date"
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                style={{ colorScheme: 'dark' }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Имеется КПП крупнейшего налогоплательщика */}
                                <div>
                                    <label className="flex flex-row items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="peer appearance-none"
                                        />
                                        <div
                                            className="
                                                        shrink-0

                                                        relative
                                                        w-11
                                                        h-6
                                                        bg-gray-200
                                                        dark:border-gray-600
                                                        dark:bg-gray-700
                                                        rounded-full
                                                        
                                                        peer
                                                        peer-focus:ring-1
                                                        peer-focus:ring-blue-300
                                                        dark:peer-focus:ring-blue-800
                                                        peer-checked:after:translate-x-full
                                                        peer-checked:after:border-white
                                                        peer-checked:bg-blue-600
                                                        
                                                        after:content-['']
                                                        after:absolute
                                                        after:top-0.5
                                                        after:start-[2px]
                                                        after:bg-white
                                                        after:border-gray-300
                                                        after:border
                                                        after:rounded-full
                                                        after:h-5
                                                        after:w-5
                                                        after:transition-all
                                                        "
                                        ></div>
                                        <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                            Имеется КПП крупнейшего
                                            налогоплательщика
                                        </span>
                                    </label>
                                </div>

                                {/* КПП крупнейшего налогоплательщика */}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                КПП крупнейшего
                                                налогоплательщика{' '}
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div className="flex w-full space-x-5 ">
                                            <div className="flex flex-1 w-full ">
                                                <input
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                />
                                            </div>
                                            <div className="flex flex-1 w-full"></div>
                                            <div className="flex flex-1 w-full"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Общая информация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Общая информация</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Идентификационный код заказчика, Часовой пояс */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Идентификационный код заказчика
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Часовой пояс
                                        </label>
                                    </div>
                                    <div>
                                        <select className="w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                            <option value="6">МСК +6</option>
                                            <option value="7">МСК +7</option>
                                            <option value="8">МСК +8</option>
                                            <option value="9">МСК +9</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Юридический адрес */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Юридический адрес{' '}
                                        <span className="text-[#FF0000]">
                                            *
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                        style={{ colorScheme: 'dark' }}
                                    />
                                </div>
                            </div>

                            {/* Почтовый адрес совпадает с юридическим */}
                            <div>
                                <label className="flex flex-row items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="peer appearance-none"
                                    />
                                    <div
                                        className="
                                                    shrink-0

                                                    relative
                                                    w-11
                                                    h-6
                                                    bg-gray-200
                                                    dark:border-gray-600
                                                    dark:bg-gray-700
                                                    rounded-full
                                                    
                                                    peer
                                                    peer-focus:ring-1
                                                    peer-focus:ring-blue-300
                                                    dark:peer-focus:ring-blue-800
                                                    peer-checked:after:translate-x-full
                                                    peer-checked:after:border-white
                                                    peer-checked:bg-blue-600
                                                    
                                                    after:content-['']
                                                    after:absolute
                                                    after:top-0.5
                                                    after:start-[2px]
                                                    after:bg-white
                                                    after:border-gray-300
                                                    after:border
                                                    after:rounded-full
                                                    after:h-5
                                                    after:w-5
                                                    after:transition-all
                                                    "
                                    ></div>
                                    <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                        Почтовый адрес совпадает с юридическим
                                    </span>
                                </label>
                            </div>

                            {/* Почтовый адрес */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Почтовый адрес{' '}
                                        <span className="text-[#FF0000]">
                                            *
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                        style={{ colorScheme: 'dark' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Классификационные признаки */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Классификационные признаки</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* ОГРН, ОКПО, ОКАТО, ОКТМО */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОГРН
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКПО{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКАТО
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКТМО
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ОКФС, Наименование */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[175px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКФС
                                        </label>
                                    </div>
                                    <div className="flex flex-row h-7 px-2 place-items-center text-center rounded-md bg-[#181818] text-white text-sm focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                        <input
                                            type="text"
                                            className="h-7 w-full rounded-md bg-[#181818]"
                                            value="42"
                                            disabled
                                        />
                                        <div>
                                            <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="bars"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col w-3/4 space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Наименование
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            value="Смешанная российская собственность с долей собственности субъектов Росси..."
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ОКОПФ, Наименование */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[175px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКОПФ{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="flex flex-row h-7 px-2 place-items-center text-center rounded-md bg-[#181818] text-white text-sm focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                        <input
                                            type="text"
                                            className="h-7 w-full rounded-md bg-[#181818]"
                                            value="12267"
                                            disabled
                                        />
                                        <div>
                                            <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="bars"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col w-3/4 space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Наименование
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            value="Непубличные акционерные общества"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ОКОГУ, Наименование */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[175px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКОГУ
                                        </label>
                                    </div>
                                    <div className="flex flex-row h-7 px-2 place-items-center text-center rounded-md bg-[#181818] text-white text-sm focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                        <input
                                            type="text"
                                            className="h-7 w-full rounded-md bg-[#181818]"
                                            value="4210014"
                                            disabled
                                        />
                                        <div>
                                            <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="bars"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col w-3/4 space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Наименование
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            value="Организации, учрежденные юридическими лицами или гражданами, или юриди..."
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Контактная информация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Контактная информация</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Контактный телефон , Примечание */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Контактный телефон{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Примечание
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Адрес электронной почты , Примечание */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Адрес электронной почты{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Примечание
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Руководитель */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Руководитель</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Должность */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Должность{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full"></div>
                                <div className="flex flex-col w-full"></div>
                            </div>

                            {/* Фамилия , Имя , Отчество (при наличии)*/}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Фамилия{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Имя{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Отчество (при наличии)
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Банковские реквизиты */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Банковские реквизиты</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3"></div>
                    </div>

                    {/* Дополнительные настройки */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Дополнительные настройки</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Почтовый адрес совпадает с юридическим */}
                            <div>
                                <label className="flex flex-row items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="peer appearance-none"
                                    />
                                    <div
                                        className="
                                                    shrink-0

                                                    relative
                                                    w-11
                                                    h-6
                                                    bg-gray-200
                                                    dark:border-gray-600
                                                    dark:bg-gray-700
                                                    rounded-full
                                                    
                                                    peer
                                                    peer-focus:ring-1
                                                    peer-focus:ring-blue-300
                                                    dark:peer-focus:ring-blue-800
                                                    peer-checked:after:translate-x-full
                                                    peer-checked:after:border-white
                                                    peer-checked:bg-blue-600
                                                    
                                                    after:content-['']
                                                    after:absolute
                                                    after:top-0.5
                                                    after:start-[2px]
                                                    after:bg-white
                                                    after:border-gray-300
                                                    after:border
                                                    after:rounded-full
                                                    after:h-5
                                                    after:w-5
                                                    after:transition-all
                                                    "
                                    ></div>
                                    <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                        Заказчик относится к организациям,
                                        информация о закупках которых не
                                        подлежит размещению на Официальном сайте
                                        ЕИС в соответствии с частью 16 статьи 4
                                        Федерального закона № 223-ФЗ
                                    </span>
                                </label>
                            </div>

                            {/* Почтовый адрес совпадает с юридическим */}
                            <div>
                                <label className="flex flex-row items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="peer appearance-none"
                                    />
                                    <div
                                        className="
                                                    shrink-0

                                                    relative
                                                    w-11
                                                    h-6
                                                    bg-gray-200
                                                    dark:border-gray-600
                                                    dark:bg-gray-700
                                                    rounded-full
                                                    
                                                    peer
                                                    peer-focus:ring-1
                                                    peer-focus:ring-blue-300
                                                    dark:peer-focus:ring-blue-800
                                                    peer-checked:after:translate-x-full
                                                    peer-checked:after:border-white
                                                    peer-checked:bg-blue-600
                                                    
                                                    after:content-['']
                                                    after:absolute
                                                    after:top-0.5
                                                    after:start-[2px]
                                                    after:bg-white
                                                    after:border-gray-300
                                                    after:border
                                                    after:rounded-full
                                                    after:h-5
                                                    after:w-5
                                                    after:transition-all
                                                    "
                                    ></div>
                                    <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                        Начальная (максимальная) цена договора
                                        включает НДС 20%
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Настройка интеграции */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Настройка интеграции</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Логин , Пароль */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Логин{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Пароль{' '}
                                            <span className="text-[#FF0000]">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col items-end">
                                <div>
                                    <button className="flex h-7 w-[180px] px-5 bg-[#187A33] rounded-lg text-[12.8px] justify-center items-center">
                                        Проверить интеграцию
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                <button className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                    Закрыть
                </button>
                <button className="flex h-7 bg-[#187A33] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                    Сохранить
                </button>
            </div>
        </div>
    )
}

export default OrgHomePage
