/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { Plan } from './types'

import Add from '../../../components/Toolbar/Add'
import '../../../components/Toolbar/Icon.css'

type ApiResponse = {
    message: Plan[]
}

export const loadPlanList = async (): Promise<Plan[]> => {
    return fetch('https://itzakupki.ru/api/backend/v1/plans', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch plans')
            }
        })
        .then((response: ApiResponse) => response.message)
        .catch((e) => {
            console.error(e)
            return []
        })
}

const PlanListPage: React.FC = () => {
    const [plans, setPlans] = useState<Plan[]>([]) // Состояние для списка планов
    const [planCounts, setPlanCounts] = useState<{
        [key: string]: number | null
    }>({})
    const [deletePlanPopup, setDeletePlanPopup] = useState(false)
    const [addPlanPopup, setAddPlanPopup] = useState(false)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [selectedPlanGuid, setSelectedPlanGuid] = useState<string | null>(
        null
    ) // Добавлено состояние для plan_guid

    const [purchasePlanNumber, setPurchasePlanNumber] = useState('')

    // const plans = useLoaderData() as Plan[]
    const navigate = useNavigate()

    const loadPlans = async () => {
        const fetchedPlans = await loadPlanList()
        setPlans(fetchedPlans)
    }

    useEffect(() => {
        loadPlans() // Загрузка данных при монтировании компонента
    }, [])

    const planPositionsCount = (plan_guid: string) => {
        fetch(
            `https://itzakupki.ru/api/backend/v1/plan_positions_count/?plan_guid=${plan_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Ошибка')
                }
                return response.json()
            })
            .then((data) => {
                setPlanCounts((prevCounts) => ({
                    ...prevCounts,
                    [plan_guid]: data.poses_count,
                }))
            })
            .catch((error) => {
                console.error(
                    'Ошибка при получении количества позиций плана:',
                    error
                )
                setPlanCounts((prevCounts) => ({
                    ...prevCounts,
                    [plan_guid]: null,
                }))
            })
    }

    useEffect(() => {
        plans.forEach((plan) => {
            if (!planCounts[plan.guid]) {
                planPositionsCount(plan.guid)
            }
        })
    }, [planCounts, plans])

    const handleDeletePlanPopup = (plan_guid: string | null = null) => {
        setSelectedPlanGuid(plan_guid) // Установить plan_guid в состояние
        setDeletePlanPopup(!deletePlanPopup)
    }

    const handleDeletePlan = async () => {
        if (!selectedPlanGuid) return

        try {
            const response = await fetch(
                `https://itzakupki.ru/api/backend/v1/plans/${selectedPlanGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении плана')
            }
            console.log('План успешно удален')
            setDeletePlanPopup(false)
            setSelectedPlanGuid(null)
            await loadPlans() // Перезагрузка данных после удаления
        } catch (error) {
            console.error('Ошибка удаления плана:', error)
        }
    }

    // Обработчик изменения поля ввода
    const handleInputChange = (event: any) => {
        const value = event.target.value.replace(/\D/g, '') // Удаляет все, кроме цифр
        if (value.length <= 10) {
            setPurchasePlanNumber(value) // Устанавливает значение только если <= 10 символов
        }
    }

    const handleAddPlanPopup = () => {
        setAddPlanPopup(!addPlanPopup)
    }

    const handleAddPlan = async (reg_number: string) => {
        setIsAdding(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                `https://itzakupki.ru/api/backend/v1/plan_from_eis`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reg_number }), // Добавлено тело запроса
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении плана')
            }
            console.log('План успешно добавлен')
            setAddPlanPopup(false)
            await loadPlans() // Перезагрузка данных после добавления
        } catch (error) {
            console.error('Ошибка при добавлении плана:', error)
        } finally {
            setIsAdding(false) // Отключаем анимацию загрузки
        }
    }

    return (
        <div className="h-full flex flex-col">
            <div className="bg-[#111111]">
                <div className="w-auto h-24 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-24 my-auto content-center ">
                        <p className="text-xs text-zinc-500">Подразделение</p>
                        <p className="text-xs text-white">Управление закупок</p>
                    </div>
                </div>
                <div className="flex flex-row cursor-pointer ">
                    <div className="w-5"></div>
                    <span className="w-60 h-7 content-center text-center text-sm text-white rounded-t bg-[#181818]">
                        Реестр планов закупки
                    </span>
                </div>
            </div>

            {/* body */}
            <div className="flex bg-white dark:bg-[#181818] p-5 duration-200 ease-in-out h-dvh justify-center">
                {/* table */}
                <div className="flex flex-col w-full min-w-[1260px]">
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg ">
                        <Add color={'#FFFFFF'} />
                    </div>
                    {/* name */}
                    <div className="flex flex-row gap-1 h-16 bg-[#262427] text-[#8A8A8A] text-[10px] p-1 ">
                        <div className="grid w-12 place-items-center text-center">
                            №
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Номер плана в ЕИС
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Версия плана
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Статус
                        </div>
                        <div className="flex-1 grid place-items-center text-center min-w-56">
                            Вид плана закупки
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Количество позиций
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Отчетный период
                        </div>
                        <div className="grid w-36 place-items-center text-center">
                            Период действия плана
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата размещения
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата утверждения
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата обновления
                        </div>
                        <div className="grid w-10 place-items-center text-center"></div>
                    </div>
                    {/* date */}
                    <div className="flex flex-col overflow-y-auto gap-1 py-1 grow bg-[#1D1C1E]">
                        {plans.map((plan, index) => (
                            <div className="flex flex-row mx-1 rounded cursor-pointer bg-[#2A282B] hover:bg-[#373538] ">
                                <div
                                    key={plan.guid}
                                    onClick={() =>
                                        navigate(
                                            '/hub/plans/' +
                                                plan.guid +
                                                '/positions'
                                        )
                                    }
                                    className="flex flex-row flex-1 gap-1 text-white text-[11px] "
                                >
                                    <div className="grid h-12 w-12 place-items-center text-center text-[#8A8A8A]">
                                        {index + 1}
                                    </div>
                                    <div className="grid h-12 w-28 place-items-center text-center">
                                        {plan.registration_number}
                                    </div>
                                    <div className="grid h-12 w-20 place-items-center text-center">
                                        {plan.version}
                                    </div>
                                    <div className="grid h-12 w-28 place-items-center text-center bg-[#2F8A43] rounded">
                                        Размещен
                                    </div>
                                    <div className="grid h-12 flex-1 items-center text-left min-w-56 px-1">
                                        <p>
                                            <a
                                                onClick={() =>
                                                    navigate(
                                                        '/hub/plans/' +
                                                            plan.guid +
                                                            '/positions'
                                                    )
                                                }
                                                className=""
                                            >
                                                {plan.plan_type === 'COMMODITY'
                                                    ? 'План закупки товаров, работ, услуг'
                                                    : 'План закупки инновационной продукции, высокотехнологичной продукции и лекарственных средств'}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="grid h-12 w-24 place-items-center text-center">
                                        {planCounts[plan.guid]
                                            ? `${planCounts[plan.guid]}`
                                            : 'Загрузка...'}
                                    </div>
                                    <div className="grid h-12 w-24 place-items-center text-center">
                                        {plan.start_date.split('-')[0]}
                                    </div>
                                    <div className="grid h-12 w-36 place-items-center text-center">
                                        {plan.start_date
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}{' '}
                                        -{' '}
                                        {plan.end_date
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}
                                    </div>
                                    <div className="grid h-12 w-24 place-items-center text-center">
                                        {plan.publication_date_time
                                            ?.slice(0, 10)
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}
                                        <br />
                                        {plan.publication_date_time?.slice(
                                            11,
                                            16
                                        )}
                                    </div>
                                    <div className="grid h-12 w-24 place-items-center text-center">
                                        {plan.approve_date
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}
                                    </div>
                                    <div className="grid h-12 w-24 place-items-center text-center">
                                        {plan.sys_updated_at
                                            ?.slice(0, 10)
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}
                                        <br />
                                        {plan.sys_updated_at?.slice(11, 16)}
                                    </div>
                                </div>
                                <div className="grid w-10 place-items-center items-center">
                                    {/* button Delete*/}
                                    <button
                                        className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                        onClick={() =>
                                            handleDeletePlanPopup(plan.guid)
                                        } // Передать plan_guid
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="trash-alt"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* buttons */}
                    <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10">
                        <button
                            className="button flex flex-row justify-start items-start"
                            onClick={handleAddPlanPopup}
                        >
                            <svg
                                className="plus-icon flex h-4 w-4 justify-start items-start mr-1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="plus"
                            >
                                <path
                                    fill="#FFF"
                                    d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                ></path>
                            </svg>{' '}
                            Добавить план
                        </button>
                    </div>
                </div>
            </div>
            {deletePlanPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleDeletePlanPopup(null)}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удалить план?</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Вы точно хотите удалить план. Действие
                                необратимо!
                            </label>
                        </div>
                        <div className="mt-5 text-right">
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                onClick={() => handleDeletePlanPopup(null)}
                            >
                                Отмена
                            </button>
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white ml-2 bg-[#494849] hover:bg-red-700"
                                onClick={handleDeletePlan} // Вызвать handleDelete
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {addPlanPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleAddPlanPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Добавление плана закупки</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Номер плана закупки в ЕИС
                            </label>
                            <input
                                type="text"
                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                value={purchasePlanNumber} // Привязка значения
                                onChange={handleInputChange} // Обновление состояния
                                maxLength={10} // Ограничение на уровне HTML
                            />
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={handleAddPlanPopup}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${
                                    purchasePlanNumber.length === 10
                                        ? 'bg-green-700 hover:bg-green-600'
                                        : 'bg-[#494849] cursor-not-allowed'
                                }`}
                                onClick={() =>
                                    handleAddPlan(purchasePlanNumber)
                                } // Вызвать handleAdd
                                disabled={
                                    purchasePlanNumber.length !== 10 || isAdding
                                }
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanListPage
