import React, { useState } from 'react'
import Section from '../../../../../../components/Section'
import MoneyInput from '../../../../../../components/form/MoneyInput'

type Props = {
    isBudgetFundsIncluded: boolean
    onBudgetFundsIncludedChange: (value: boolean) => void
}

const _getTable = (props: Props) => {
    return (
        <div className="flex flex-col w-full min-w-fit mt-5 bg-[#242225] rounded">
            {/* toolbar */}
            <div className="flex flex-row gap-1 place-items-center text-white p-2 h-10 bg-[#333033] rounded-t ">
                <button className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                    <svg
                        className="plus-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="plus"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                        ></path>
                    </svg>
                </button>
            </div>

            {/* name */}
            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                <div className="grid w-10 place-items-center text-center">
                    №
                </div>
                <div className="flex-1 grid place-items-center text-center">
                    Код целевой статьи расходов
                </div>
                <div className="flex-1 grid place-items-center text-center">
                    Код вида расходов (КВР)
                </div>
                <div className="grid w-40 place-items-center text-center">
                    Объем финансового обеспечения (руб.)
                </div>
                <div className="grid w-8 place-items-center text-center"></div>
            </div>

            {/* date */}
            <div className="flex flex-row gap-1 my-1 text-[11px]">
                <div className="grid w-10 place-items-center text-center text-[#8A8A8A]">
                    1
                </div>

                <div className="flex flex-row flex-1 h-8 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                    <input
                        type="text"
                        className="h-8 rounded-md bg-[#181818]"
                    />
                    <div>
                        <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="bars"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="flex flex-row flex-1 h-8 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                    <input
                        type="text"
                        className="h-8 w-full rounded-md bg-[#181818]"
                        // value="positionRow.okved2"
                    />
                    <div>
                        <button className="w-6 h-6 grid rounded-full place-items-center bg-[#181818] hover:bg-[#656365]">
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="bars"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="flex flex-row h-8 w-44 pl-2 pr-1 place-items-center text-center rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                    <MoneyInput
                        className={
                            'form-input w-full text-xs bg-[#1F1E1F] border border-[#2A282B]'
                        }
                    />
                </div>

                <div className="flex flex-row h-8 w-8 place-items-center text-center">
                    <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="trash-alt"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* buttons */}
            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10 space-x-2">
                <button className="rounded-full px-4 h-6 text-[12.8px] bg-[#494849] hover:bg-[#656365]">
                    + Добавить позицию
                </button>
            </div>
        </div>
    )
}

const PurchaseBudgetFunds: React.FC<Props> = (props) => {
    return (
        <Section title="Информация о финансировании за счет бюджетных средств">
            <div className="flex flex-row gap-x-5">
                <div className="flex flex-col">
                    <span className="form-label">
                        Наличие бюджетных средств
                    </span>
                    <select
                        className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={props.isBudgetFundsIncluded ? 'yes' : 'no'}
                        onChange={(e) =>
                            props.onBudgetFundsIncludedChange(
                                e.target.value === 'yes'
                            )
                        }
                    >
                        <option value="yes">Да</option>
                        <option value="no">Нет</option>
                    </select>
                </div>
                {props.isBudgetFundsIncluded && (
                    <div className="flex flex-col">
                        <span className="form-label">
                            Объем бюджетных средств
                        </span>
                        <MoneyInput
                            disabled={true}
                            className="form-input bg-[#1F1E1F] border border-[#2A282B]"
                        />
                    </div>
                )}
            </div>
            {props.isBudgetFundsIncluded && _getTable(props)}
        </Section>
    )
}

export default PurchaseBudgetFunds
