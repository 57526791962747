import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

const ErrorPage: React.FC = () => {
    const error = useRouteError()
    const message = isRouteErrorResponse(error) ? error.statusText : null

    return (
        <div>
            <h1>Oops!</h1>
            <p>{message}</p>
        </div>
    )
}

export default ErrorPage
