export type User = {
    token: string
}

const shouldUpdateToken = (): boolean => {
    const refreshTime = localStorage.getItem('refresh_time')
    if (refreshTime == null) {
        return true
    }
    // more than a day
    return Date.now() - Number(refreshTime) > 86_400_000
}

export const getUserData = async (): Promise<User | null> => {
    console.log('checking token...')

    if (false && window.document.location.hostname.startsWith('local')) {
        return new Promise((resolver) => {
            resolver({ token: 'local_fake_user' })
        })
    }

    return fetch('https://itzakupki.ru/api/auth/v1/check_jwt/', {
        method: 'GET',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((r) => {
            console.log('token check result: ' + r.ok)

            if (r.ok && shouldUpdateToken()) {
                console.log('updating token...')

                return fetch(
                    'https://itzakupki.ru/api/auth/v1/users/token/refresh/',
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                ).then((resp) => {
                    if (resp.ok) {
                        console.log('token updated')
                        localStorage.setItem(
                            'refresh_time',
                            Date.now().toString()
                        )
                        return true
                    } else {
                        console.log('token is not updated')
                        return false
                    }
                })
            }

            return r.ok
        })
        .then((isTokenValid) => (isTokenValid ? { token: 'cookie' } : null))
        .catch(() => null)
}
