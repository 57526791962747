import React from 'react'
import './index.css'
import { PlanPosition } from '../../types'

type Props = {
    planPosition: PlanPosition
    selected: boolean
    onClick: (ordinal_number: number) => void
    onDoubleClick: (pos_guid: string) => void
}

const MONTHS = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
]

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

const POSITION_STATUS = new Map([
    ['P', 'Размещена'],
    ['A', 'Аннулирована'],
    ['N', 'Новая'],
    ['C', 'Изменена'],
])

const PlanPositionsTableRow: React.FC<Props> = ({
    planPosition,
    selected,
    onClick,
    onDoubleClick,
}) => {
    return (
        <div
            className={
                selected
                    ? 'plan-position-table-row-selected'
                    : 'plan-position-table-row'
            }
            onDoubleClick={() => onDoubleClick(planPosition.guid)}
            onClick={() => onClick(planPosition.ordinal_number)}
        >
            <div className="grid h-24 w-8 place-items-center text-center">
                <input
                    type="checkbox"
                    checked={selected}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                        onClick(planPosition.ordinal_number)
                    }}
                />
            </div>
            <div className="grid h-24 w-12 place-items-center text-center text-[#8A8A8A]">
                {planPosition.ordinal_number}
            </div>
            <div className="grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded">
                {planPosition.position_stage}
            </div>
            <div className="grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded">
                {planPosition.position_status}
            </div>
            <div className="grid h-24 flex-1 place-items-center justify-items-start text-left min-w-44 px-2 ">
                <p className="fourrow">{planPosition.contract_subject}</p>
            </div>
            <div className="grid h-24 w-28 place-items-center justify-items-end text-right px-2 ">
                {Number(planPosition.maximum_contract_price).toLocaleString(
                    'ru-RU',
                    {
                        minimumFractionDigits: 2,
                    }
                ) +
                    ' ' +
                    CURRENCY.get(String(planPosition.currency_digital_code))}
            </div>
            <div className="grid h-24 w-24 place-items-center justify-items-end text-right px-2 ">
                {planPosition.purchase_planned_date === null ? (
                    <>
                        {MONTHS[
                            Number(planPosition.purchase_period_month) - 1
                        ] + ' '}
                        {planPosition.purchase_period_year}
                    </>
                ) : (
                    planPosition.purchase_planned_date
                        .split('-')
                        .reverse()
                        .join('-')
                        .replace(/[\-]/g, '.')
                )}
            </div>
            <div className="grid h-24 w-24 place-items-center justify-items-end text-right px-2 ">
                {
                    <>
                        {MONTHS[
                            Number(
                                planPosition.contract_end_date?.slice(5, 7)
                            ) - 1
                        ] + ' '}
                        {planPosition.contract_end_date?.slice(0, 4)}
                    </>
                }
            </div>
            <div className="grid h-24 w-44 place-items-center text-center">
                {planPosition.purchase_method_name}
            </div>
            <div
                className={
                    planPosition.status === 'P'
                        ? 'grid h-24 w-24 place-items-center text-center bg-[#2F8A43] rounded'
                        : 'grid h-24 w-24 place-items-center text-center bg-[#A63636] rounded'
                }
            >
                {POSITION_STATUS.get(String(planPosition.status))}
            </div>
            <div className="grid h-24 w-20 place-items-center text-center">
                Тут будет
                <br />
                "Тип объекта закупки"
            </div>
            <div className="grid h-24 w-20 place-items-center text-center">
                Тут будет
                <br />
                "Подразделение"
            </div>
            <div className="grid h-24 w-20 place-items-center text-center">
                Тут будет
                <br />
                "Статья"
            </div>
            <div className="grid h-24 w-28 place-items-center text-center text-[#8A8A8A]">
                Тут будет
                <br />
                "Номер в система"
            </div>
            <div className="grid h-24 w-20 place-items-center text-center text-[#8A8A8A]">
                {planPosition.last_synced_time
                    ?.slice(0, 10)
                    .split('-')
                    .reverse()
                    .join('-')
                    .replace(/[\-]/g, '.')}
                <br />
                {planPosition.last_synced_time?.slice(11, 16)}
            </div>
            {/* <div className="grid h-24 w-12 place-items-center text-center">
    <div className="text-xs text-white bg-[#3a3a3a] w-7 h-7 rounded-full mx-auto my-auto content-center  ">
        AA
    </div>
</div> */}
        </div>
    )
}

export default PlanPositionsTableRow
