import React from 'react'

const OrgHandbookOkpd2Page: React.FC = () => {
    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            ОКПД 2 - Общероссийский классификатор продукции по
                            экономической деятельности
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg"></div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-14 place-items-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-20 justify-center items-center text-center">
                                    <span>Классификация</span>
                                    <span>по ОКПД2</span>
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Наименование справочника
                                </div>
                                <div className="flex flex-col w-32 justify-center items-center text-center">
                                    <span>Закупка</span>
                                    <span>в электронной форме</span>
                                </div>
                                <div className="flex flex-col w-32 justify-center items-center text-center">
                                    Закупка МСП
                                </div>
                                <div className="flex flex-col w-3 justify-center items-center text-center"></div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-dvh bg-[#1D1C1E]">
                                {/* Вывод списка ОКПД2 */}
                                <>
                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            1
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            58.29.29.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Обеспечение программное прикладное
                                            прочее на электронном носителе
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            2
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            59.12.12
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по переносу и дублированию
                                            первых оригиналов
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            3
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            35.11
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Электроэнергия, произведенная
                                            блок-станциями конденсационных
                                            электростанций (КЭС)
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            4
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            35.12.10.120
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по технологическому
                                            присоединению к распределительным
                                            электросетям
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            6
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            7
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            8
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            9
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            10
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            11
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            12
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            13
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            14
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            15
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            16
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            17
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>

                                    <div className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            5
                                        </div>
                                        <div className="flex flex-col w-20 justify-center items-start text-center">
                                            68.10.11.000
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            Услуги по покупке и продаже жилых
                                            зданий и занимаемых ими земельных
                                            участков
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            -
                                        </div>
                                        <div className="flex flex-col w-32 justify-center items-center text-center">
                                            Да
                                        </div>
                                    </div>
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgHandbookOkpd2Page
