import React from 'react'
import { useNavigate } from 'react-router-dom'

const OrgListMspPage: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col text-white min-h-screen">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-full h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Перечень товаров, работ, услуг, закупки которых
                            осуществляются у субъектов малого и среднего
                            предпринимательства
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center h-[calc(100vh_-_120px)] p-5">
                <div className="flex flex-col w-[1260px] space-y-10">
                    {/* Общие сведения */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Общие сведения</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Дата размещения, Период действия */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[200px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Дата размещения
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            disabled
                                            value="28.10.2024"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-[200px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Период действия
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            disabled
                                            value="с 01.01.2015 по 31.12.2027"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Причина изменения сведений */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Причина изменения сведений
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                        style={{ colorScheme: 'dark' }}
                                        value="Корректировка перечня на 28.10.2024 год"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col h-full bg-[#262427] rounded-lg p-5 space-y-5 overflow-y-auto">
                        {/* table */}
                        <div className="flex flex-col h-full bg-[#242225] rounded">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t place-items-center">
                                <button className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="filter"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                        ></path>
                                    </svg>
                                </button>

                                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                                    Позиций <br />6
                                </div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-12 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-12 place-items-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-32 justify-center items-center text-center">
                                    <span>Классификация</span>
                                    <span>по ОКПД2</span>
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Наименование товара, работы, услуги
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 my-1 h-full">
                                {/* Вывод ТРУ */}
                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-32 place-items-center items-center px-2">
                                        31.01.11.121
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Издание прочих программных продуктов
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        2
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        36.00.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Вода питьевая
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        3
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        25.93.15.110
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Проволока, прутки присадочные, стержни,
                                        пластины
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        4
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        25.93.15.120
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Электроды с покрытием
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        5
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        25.93.15.130
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Проволока с флюсовым сердечником
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-12 place-items-center text-center text-[#8A8A8A]">
                                        6
                                    </div>
                                    <div className="grid w-32 place-items-center justify-items-center px-2">
                                        85.31.11.000
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Услуги по профессиональному обучению
                                    </div>
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                <div className="flex flex-1 w-auto items-start">
                    <button className="flex h-7 bg-[#187A33] w-[180px] rounded-lg text-[12.8px] justify-center items-center">
                        Импорт данных
                    </button>
                </div>
                <div className="flex items-center space-x-5">
                    <button
                        className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center"
                        onClick={() => navigate('/hub/org/lists')}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OrgListMspPage
