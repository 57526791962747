import React from 'react'

interface Props {
    label: string
    checked: boolean
    onChange: (checked: boolean) => void
}

const Checkbox: React.FC<Props> = ({ label, checked, onChange }) => {
    return (
        <label className="flex flex-row items-center cursor-pointer">
            <input
                type="checkbox"
                className="peer appearance-none"
                checked={checked}
                onChange={(event) => onChange(event.target.checked)}
            />
            <div
                className="
        shrink-0

        relative
        w-11
        h-6
        bg-gray-200
        dark:border-gray-600
        dark:bg-gray-700
        rounded-full
        
        peer
        peer-focus:ring-1
        peer-focus:ring-blue-300
        dark:peer-focus:ring-blue-800
        peer-checked:after:translate-x-full
        peer-checked:after:border-white
        peer-checked:bg-blue-600
        
        after:content-['']
        after:absolute
        after:top-0.5
        after:start-[2px]
        after:bg-white
        after:border-gray-300
        after:border
        after:rounded-full
        after:h-5
        after:w-5
        after:transition-all
        "
            ></div>
            <span className="form-label ml-2">{label}</span>
        </label>
    )
}

export default Checkbox
