import React, { useState, useEffect } from 'react'

import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { PlanPosition, PlanPositionStage } from './types'

import './style.css'

import Add from '../../../../components/Toolbar/Add'
import Copy from '../../../../components/Toolbar/Copy'
import '../../../../components/Toolbar/Icon.css'
import PlanPositionsHeader from './components/Header'
import PlanPositionsTabs from './components/Tabs'
import PlanPositionsTableRow from './components/TableRow'

const PlanPositionListPage: React.FC = () => {
    const navigate = useNavigate()
    const { plan_guid } = useParams()

    const [stage, setStage] = useState<PlanPositionStage>(
        PlanPositionStage.EDIT
    )

    const [isLoading, setLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PlanPosition[]>([])

    const loadPlanPositions = () => {
        setLoading(true)

        const postfix =
            stage === PlanPositionStage.PURCHASE_PLAN_IN_EIS ? '' : '_draft'

        fetch(
            'https://itzakupki.ru/api/backend/v1/plan_positions' +
                postfix +
                '/?plan_guid=' +
                encodeURIComponent(plan_guid as string),
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch plan positions')
                }
            })
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((data) => {
                setItems(
                    data.filter(
                        (item: PlanPosition) => item.position_stage === stage
                    )
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // initial load & on tab change
    useEffect(() => {
        loadPlanPositions()
    }, [stage])

    const [selectedItems, setSelectedItems] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)

    // Функция для обработки изменения состояния отдельного checkbox
    const handleCheckboxChange = (ordinal_namber: number) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(ordinal_namber)) {
                return prevSelectedItems.filter(
                    (itemId) => itemId !== ordinal_namber
                )
            } else {
                return [...prevSelectedItems, ordinal_namber]
            }
        })
    }

    // Функция для обработки изменения состояния "Select All"
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            setSelectedItems(items.map((item) => item.ordinal_number)) // Выбираем все элементы
        } else {
            setSelectedItems([]) // Сбрасываем выбор всех элементов
        }
    }

    // При изменении данных в таблице обновляем выбор "Select All"
    useEffect(() => {
        if (selectAll && selectedItems.length !== items.length) {
            setSelectedItems(items.map((item) => item.ordinal_number))
        }
    }, [items, selectAll])

    const handleDeleteClick = () => {
        alert('Вы хотите удалить позиции:' + selectedItems)
        console.log('Selected items:', selectedItems)
    }

    const initialValue = 0
    const sum = items.reduce(
        (accumulator, currentValue) =>
            accumulator + Number(currentValue.maximum_contract_price),
        initialValue
    )

    return (
        <div className="h-full flex flex-col bg-[#181818]">
            <div className="bg-[#111111]">
                <PlanPositionsHeader positionCount={items.length} sum={sum} />
                <PlanPositionsTabs
                    stage={stage}
                    onTabChange={(newStage) => {
                        setStage(newStage)
                    }}
                />
            </div>

            {/* // body */}
            <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
                {/* table */}
                <div className="flex flex-col w-full bg-[#1D1C1E] rounded">
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t">
                        <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                            <input
                                type="checkbox"
                                className="p-2"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        </div>

                        <Add color={'#FFFFFF'} />
                        <Copy color={'#FFFFFF'} />

                        <button
                            className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                            onClick={handleDeleteClick}
                        >
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 22 22"
                                id="trash-alt"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    {/* name */}
                    <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                        <div className="grid w-8 place-items-center text-center"></div>
                        <div className="grid w-12 place-items-center text-center">
                            №
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Стадия
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус
                        </div>
                        <div className="flex-1 grid place-items-center text-center min-w-44">
                            Предмет договора
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Начальная (максимальная) цена договора
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата (период) размещения извещения
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Срок исполнения договора
                        </div>
                        <div className="grid w-44 place-items-center text-center">
                            Способ закупки
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус в плане закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Тип объекта закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Подразделение
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Статья
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Номер в системе
                        </div>
                        <div className="grid w-20 place-items-center text-center pr-5">
                            Дата последнего изменения
                        </div>
                        {/* <div className="grid w-12 place-items-center text-center">
                            ДД
                        </div> */}
                    </div>

                    {/* data */}
                    <div className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh">
                        {/* Вывод позиций */}
                        <>
                            {isLoading ? (
                                <div className="mt-5 text-center text-sm text-white">
                                    Загрузка...
                                </div>
                            ) : (
                                items.map((item, index) => (
                                    <PlanPositionsTableRow
                                        key={index}
                                        planPosition={item}
                                        selected={selectedItems.includes(
                                            item.ordinal_number
                                        )}
                                        onClick={(num) => {
                                            handleCheckboxChange(num)
                                        }}
                                        onDoubleClick={(pos_guid) => {
                                            navigate(
                                                '/hub/plans/' +
                                                    plan_guid +
                                                    '/positions/' +
                                                    pos_guid +
                                                    '/edit'
                                            )
                                        }}
                                    />
                                ))
                            )}
                        </>
                    </div>

                    {/* buttons */}
                    <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10">
                        <button className="button">+ Создать позицию</button>
                        <button className="button">Скопировать</button>
                        <button className="button">Печать</button>
                        <button className="button">Скачать</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanPositionListPage
