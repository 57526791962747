import React from 'react'
import Section from '../../../../../../components/Section'
import Checkbox from '../../../../../../components/form/Checkbox'

interface Props {
    method: string
    onMethodChange: (value: string) => void
    basisSingleSupplier?: string
    onBasisSingleSupplierChange?: (value: string) => void
    areParticipantsSME: boolean
    onAreParticipantsSMEChange: (value: boolean) => void
    isPlannedThirdYear: boolean
    onIsPlannedThirdYearChange: (value: boolean) => void
    isInnovativeProduct: boolean
    onIsInnovativeProductChange: (value: boolean) => void
    isExcludedForSME: boolean
    onIsExcludedForSMEChange: (value: boolean) => void
    category?: string
    onCategoryChange?: (value: string) => void
}

const PurchaseMethod: React.FC<Props> = (props) => {
    const { basisSingleSupplier, onBasisSingleSupplierChange } = props
    const { category, onCategoryChange } = props
    return (
        <Section title="Информация о закупке">
            <div className="form-label">Способ закупки</div>
            <select
                className="w-full form-select"
                value={props.method}
                onChange={(e) => props.onMethodChange(e.target.value)}
            >
                <option disabled>Выбрать способ закупки</option>
                <option value="plan">
                    Способ закупки в соответствии с планом закупки
                </option>
                <option value="eshop">Электронный магазин</option>
                <option value="small">Малая закупка</option>
                <option value="price_request">Запрос цен</option>
                <option value="single_supplier">
                    Закупка у единственного поставщика (исполнителя, подрядчика)
                </option>
            </select>

            {basisSingleSupplier && onBasisSingleSupplierChange && (
                <>
                    <div className="mt-2 form-label">
                        Основание для проведения закупки у единственного
                        поставщика (исполнителя, подрядчика)
                    </div>
                    <select
                        className="w-full form-select"
                        value={basisSingleSupplier}
                        onChange={(e) =>
                            onBasisSingleSupplierChange(e.target.value)
                        }
                    >
                        <option>1) Закупка до 500 000,00 рублей</option>
                        <option>
                            15) осуществляется поставки товаров, выполнение
                            работ, оказание услуг для нужд Заказщика на сумму не
                            превыщающую пять миллионов рублей (без учета НДС)
                        </option>
                    </select>
                </>
            )}

            <div className="mt-5 flex flex-col space-y-2">
                <Checkbox
                    label="Закупка, участниками которой являются только субъекты малого и среднего предпринимательства"
                    checked={props.areParticipantsSME}
                    onChange={props.onAreParticipantsSMEChange}
                />

                <Checkbox
                    label="Закупка запланирована на третий или последующие годы реализации плана закупки"
                    checked={props.isPlannedThirdYear}
                    onChange={props.onIsPlannedThirdYearChange}
                />

                <Checkbox
                    label="Закупка товаров, работ, услуг, удовлетворяющих критериям отнесения к инновационной продукции, высокотехнологичной продукции"
                    checked={props.isInnovativeProduct}
                    onChange={props.onIsInnovativeProductChange}
                />
                <Checkbox
                    label="Закупка исключается при расчете годового объема закупок, участниками которых являются субъекты малого и среднего предпринимательства"
                    checked={props.isExcludedForSME}
                    onChange={props.onIsExcludedForSMEChange}
                />
            </div>

            {category && onCategoryChange && (
                <>
                    <div className="mt-5 form-label">
                        Категория закупки в соответствии с пунктом 7
                        постановления Правительства РФ от 11.12.2014 № 1352
                    </div>
                    <select
                        className="w-full form-select"
                        value={category}
                        onChange={(e) => onCategoryChange(e.target.value)}
                    >
                        <option>
                            2. Закупки в области науки использования атомной
                            энергии
                        </option>
                    </select>
                </>
            )}
        </Section>
    )
}

export default PurchaseMethod
