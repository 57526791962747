import React from 'react'
import './style.css'

interface TableChartFHDProps {
    plan_guid: string
}

const TableChartFHD: React.FC<TableChartFHDProps> = ({ plan_guid }) => {
    return (
        <>
            <div className="flex flex-col w-full h-full relative">
                <div className="flex flex-row text-white text-[16px] mb-4">
                    <div className="flex flex-1">
                        Объем закупок по статьям ФХД
                    </div>
                    <button className="w-5 h-5">
                        <svg
                            id="info-circle"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#FFF"
                                d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col w-full h-[335px] relative rounded-lg ">
                    <div className="flex flex-row w-full bg-[#333033] text-[#8A8A8A] text-[10px] rounded-t p-1">
                        <div className="flex w-10 h-8 justify-center items-center">
                            №
                        </div>
                        <div className="flex flex-1 w-full h-8 justify-center items-center">
                            Наименование способа закупки
                        </div>
                        <div className="flex w-20 h-8 justify-center items-center">
                            Количество
                        </div>
                        <div className="flex w-36 h-8 justify-center items-center">
                            Сумма
                        </div>
                    </div>
                    <div className="flex-col bg-[#1D1C1E] p-1 h-full space-y-1 rounded-b overflow-y-auto">
                        {plan_guid == 'Нет данных' ? (
                            <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                                Нет данных...
                            </div>
                        ) : (
                            <>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        1
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-1
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        150
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        8 158 987,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        2
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-2
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        80
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        3 978 153,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                                <div className="flex w-full h-10 bg-[#2A282B] rounded text-white text-[12.8px] space-x-1">
                                    <div className="flex w-10 text-[#8A8A8A] justify-center items-center">
                                        3
                                    </div>
                                    <div className="flex flex-1 w-full justify-start items-center">
                                        ФХД-3
                                    </div>
                                    <div className="flex w-20 justify-center items-center">
                                        120
                                    </div>
                                    <div className="flex w-36 justify-center items-center">
                                        6 112 357,00 Р
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableChartFHD
