import React from 'react'
import { useLoaderData } from 'react-router-dom'
import { User } from './types'

export const loadUser = async ({ params }: any): Promise<User> => {
    // params.id is "new"
    // todo: load user by id from server
    return new Promise((resolver) => {
        resolver({ id: params.id, name: 'User Name' })
    })
}

const OrgUserViewOrEditPage: React.FC = () => {
    const user = useLoaderData() as User
    const isEdit = window.location.hash.endsWith('/edit')
    return (
        <span className="text-white">
            {isEdit ? (
                <>
                    <div className="flex flex-col text-white h-full">
                        <div className="bg-[#111111]">
                            <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                                <div className="w-60 h-20 my-auto content-center ">
                                    <p className="text-sm text-white">
                                        Реестр пользователей
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center min-h-[calc(100vh_-_124px)] overflow-y-auto py-5">
                            <div className="flex flex-col h-max w-[600px] space-y-10">
                                {/* Моя организация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Профиль пользователя</p>
                                    </div>

                                    {/* Активный пользователь */}
                                    <div>
                                        <label className="flex flex-row items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="peer appearance-none"
                                            />
                                            <div
                                                className="
                                                            shrink-0

                                                            relative
                                                            w-11
                                                            h-6
                                                            bg-gray-200
                                                            dark:border-gray-600
                                                            dark:bg-gray-700
                                                            rounded-full
                                                            
                                                            peer
                                                            peer-focus:ring-1
                                                            peer-focus:ring-blue-300
                                                            dark:peer-focus:ring-blue-800
                                                            peer-checked:after:translate-x-full
                                                            peer-checked:after:border-white
                                                            peer-checked:bg-blue-600
                                                            
                                                            after:content-['']
                                                            after:absolute
                                                            after:top-0.5
                                                            after:start-[2px]
                                                            after:bg-white
                                                            after:border-gray-300
                                                            after:border
                                                            after:rounded-full
                                                            after:h-5
                                                            after:w-5
                                                            after:transition-all
                                                            "
                                            ></div>
                                            <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                                Активный пользователь
                                            </span>
                                        </label>
                                    </div>

                                    <div className="flex flex-row space-x-5">
                                        <div className="flex items-start">
                                            <div className="h-[100px] w-[100px] grid bg-[#181818] rounded-full place-items-center">
                                                <div className="flex flex-col items-center">
                                                    <button className="w-6 h-6">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="image-plus"
                                                        >
                                                            <path
                                                                fill="#8A8A8A"
                                                                d="M19,10a1,1,0,0,0-1,1v3.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.71L9.41,11.12a2.79,2.79,0,0,0-3.93,0L4,12.61V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19.22A2.79,2.79,0,0,0,4.78,22H17.22a2.88,2.88,0,0,0,.8-.12h0a2.74,2.74,0,0,0,2-2.65V11A1,1,0,0,0,19,10ZM5,20a1,1,0,0,1-1-1V15.43l2.89-2.89a.78.78,0,0,1,1.1,0L15.46,20Zm13-1a1,1,0,0,1-.18.54L13.3,15l.71-.7a.77.77,0,0,1,1.1,0L18,17.21ZM21,4H20V3a1,1,0,0,0-2,0V4H17a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    <p className="text-[#8A8A8A] text-xs">
                                                        Загрузить
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full space-y-3">
                                            {/* Фамилия */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Фамилия
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Имя */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Имя
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Отчество (при наличии) */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Отчество (при наличии)
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Дата рождения, Пол*/}
                                            <div className="flex flex-row w-full space-x-5 ">
                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Дата рождения
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                            style={{
                                                                colorScheme:
                                                                    'dark',
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Пол
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <select className="w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                                            <option value="0">
                                                                Выбрать
                                                            </option>
                                                            <option value="1">
                                                                Мужской
                                                            </option>
                                                            <option value="2">
                                                                Женский
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Общая информация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Общая информация</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Контактный телефон, Добавочный номер */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-2/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Контактный телефон
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col w-1/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Добавочный номер
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Должность */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Должность{' '}
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* Подразделение */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Подразделение
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <select className="w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                                        <option value="0">
                                                            Выбрать
                                                        </option>
                                                        <option value="1">
                                                            Административно-управленческий
                                                            персонал
                                                        </option>
                                                        <option value="2">
                                                            УГРС
                                                        </option>
                                                        <option value="3">
                                                            ЛПУМГ
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Роль */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Роль
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <select className="w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]">
                                                        <option value="0">
                                                            Выбрать
                                                        </option>
                                                        <option value="1">
                                                            Администратор
                                                        </option>
                                                        <option value="2">
                                                            Специалист по
                                                            закупкам
                                                        </option>
                                                        <option value="3">
                                                            Инициатор закупки
                                                        </option>
                                                        <option value="4">
                                                            Куратор инициаторов
                                                        </option>
                                                        <option value="5">
                                                            Гость
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Учетные данные */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Учетные данные</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Электронная почта */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Электронная почта
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* Пароль учетной записи */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Пароль учетной записи
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* buttons */}
                        <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                            <button className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                                Закрыть
                            </button>
                            <button className="flex h-7 bg-[#187A33] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                                Сохранить
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                'Viewing'
            )}
        </span>
    )
}

export default OrgUserViewOrEditPage
