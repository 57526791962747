import React, { useState } from 'react'
import { PurchaseMethod } from './types'
import { useLoaderData } from 'react-router-dom'

interface Option {
    value: string
    optionStyle: string
}

export const loadPurchaseMethod = async ({
    params,
}: any): Promise<PurchaseMethod> => {
    // todo: load purchase method by id from server
    return new Promise((resolver) => {
        resolver({ id: params.id })
    })
}

const OrgPurchaseMethodViewOrEdit: React.FC = () => {
    const [selectedMethodType, setSelectedMethodType] = useState('Выбрать')
    const [selectedMethodKind, setSelectedMethodKind] = useState('')
    const [selectedTypicalKind, setSelectedTypicalKind] = useState('')
    const [selectedCompetitiveType, setSelectedCompetitiveType] = useState('')
    const [selectedMethodForm, setSelectedMethodForm] = useState('')

    const methodTypeOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Типовой', optionStyle: 'text-[#FFF]' },
        { value: 'Иной', optionStyle: 'text-[#FFF]' },
    ]

    const methodKindOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Открытый', optionStyle: 'text-[#FFF]' },
        { value: 'Закрытый', optionStyle: 'text-[#FFF]' },
    ]

    const typicalKindOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Конкурс', optionStyle: 'text-[#FFF]' },
        { value: 'Аукцион', optionStyle: 'text-[#FFF]' },
        { value: 'Запрос предложений', optionStyle: 'text-[#FFF]' },
        { value: 'Запрос котировок', optionStyle: 'text-[#FFF]' },
        {
            value: 'Закупка у единственного поставщика',
            optionStyle: 'text-[#FFF]',
        },
    ]

    const competitiveTypeOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Конкурентный', optionStyle: 'text-[#FFF]' },
        { value: 'Неконкурентный', optionStyle: 'text-[#FFF]' },
    ]

    const methodFormOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'В электронной форме', optionStyle: 'text-[#FFF]' },
        { value: 'В бумажной форме', optionStyle: 'text-[#FFF]' },
    ]

    const purchaseMethod = useLoaderData() as PurchaseMethod
    const isEdit = window.location.hash.endsWith('/edit')

    const handleMethodTypeChange = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedMethodType(event.target.value)
        switch (event.target.value) {
            case 'Выбрать':
                setSelectedMethodKind('')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Типовой':
                setSelectedMethodKind('Выбрать')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Иной':
                setSelectedMethodKind('Выбрать')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
        }
    }

    const handleMethodKindChange = (value: string, methodType: string) => {
        setSelectedMethodKind(value)
        switch (value) {
            case 'Выбрать':
                setSelectedTypicalKind('Выбрать')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Открытый':
                if (methodType === 'Иной') {
                    setSelectedTypicalKind('')
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                } else {
                    setSelectedTypicalKind('Выбрать')
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                }
                break
            case 'Закрытый':
                if (methodType === 'Иной') {
                    setSelectedTypicalKind('')
                    setSelectedCompetitiveType('Конкурентный')
                    setSelectedMethodForm('В электронной форме')
                } else {
                    setSelectedTypicalKind('Выбрать')
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                }
                break
            default:
                setSelectedTypicalKind('Выбрать')
                setSelectedCompetitiveType('Выбрать')
                setSelectedMethodForm('')
                break
        }
    }

    const handleTypicalKindChange = (value: string, methodKind: string) => {
        setSelectedTypicalKind(value)
        switch (value) {
            case 'Выбрать':
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Закупка у единственного поставщика':
                setSelectedCompetitiveType('Неконкурентный')
                setSelectedMethodForm('Выбрать')
                break
            case 'Запрос предложений':
                setSelectedCompetitiveType('Конкурентный')
                setSelectedMethodForm('В электронной форме')
                break
            case 'Запрос котировок':
                setSelectedCompetitiveType('Конкурентный')
                setSelectedMethodForm('В электронной форме')
                break
            default:
                if (methodKind === 'Закрытый') {
                    setSelectedCompetitiveType('Конкурентный')
                    setSelectedMethodForm('В электронной форме')
                } else {
                    setSelectedCompetitiveType('Конкурентный')
                    setSelectedMethodForm('Выбрать')
                }

                break
        }
    }

    const handleCompetitiveType = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedCompetitiveType(event.target.value)
        setSelectedMethodForm('Выбрать')
    }

    const handleMethodForm = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedMethodForm(event.target.value)
    }

    return (
        <>
            {isEdit ? (
                <>
                    <div className="flex flex-col text-white h-full">
                        <div className="bg-[#111111]">
                            <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                                <div className="w-60 h-20 my-auto content-center ">
                                    <p className="text-sm text-white">
                                        Реестр способов закупки
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center min-h-[calc(100vh_-_120px)] overflow-y-auto py-5">
                            <div className="flex flex-col h-max w-[800px] space-y-10">
                                {/* Способы закупки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Способ закупки</p>
                                    </div>

                                    <div className="flex flex-row space-x-5">
                                        <div className="flex flex-col w-full space-y-3">
                                            {/* Активный способ закупки */}
                                            <div>
                                                <label className="flex flex-row items-center cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        className="peer appearance-none"
                                                    />
                                                    <div
                                                        className="
                                                                    shrink-0

                                                                    relative
                                                                    w-11
                                                                    h-6
                                                                    bg-gray-200
                                                                    dark:border-gray-600
                                                                    dark:bg-gray-700
                                                                    rounded-full
                                                                    
                                                                    peer
                                                                    peer-focus:ring-1
                                                                    peer-focus:ring-blue-300
                                                                    dark:peer-focus:ring-blue-800
                                                                    peer-checked:after:translate-x-full
                                                                    peer-checked:after:border-white
                                                                    peer-checked:bg-blue-600
                                                                    
                                                                    after:content-['']
                                                                    after:absolute
                                                                    after:top-0.5
                                                                    after:start-[2px]
                                                                    after:bg-white
                                                                    after:border-gray-300
                                                                    after:border
                                                                    after:rounded-full
                                                                    after:h-5
                                                                    after:w-5
                                                                    after:transition-all
                                                                    "
                                                    ></div>
                                                    <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                                        Активный способ закупки
                                                    </span>
                                                </label>
                                            </div>

                                            {/* Наименование способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Наименование способа
                                                        закупки
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Сокращенное наименование способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Сокращенное наименование
                                                        способа закупки
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Код способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Код способа закупки
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Свойства способа закупки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Свойства способа закупки</p>
                                    </div>
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodType}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodKind}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedTypicalKind}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedCompetitiveType}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodForm}
                                        </p>
                                    }

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Тип способа закупки */}
                                        <div className="flex flex-col space-y-1 w-[200px]">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Тип способа закупки
                                                </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={selectedMethodType}
                                                    onChange={
                                                        handleMethodTypeChange
                                                    }
                                                    className={
                                                        selectedMethodType ===
                                                        'Выбрать'
                                                            ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                            : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                    }
                                                >
                                                    {methodTypeOptions.map(
                                                        (option) =>
                                                            option.value ===
                                                            'Выбрать' ? (
                                                                <option
                                                                    disabled
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Вид способа закупки */}
                                        <div
                                            className={
                                                selectedMethodKind === ''
                                                    ? 'flex-col space-y-1 w-[200px] hidden'
                                                    : 'flex flex-col space-y-1 w-[200px]'
                                            }
                                        >
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Вид способа закупки
                                                </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={selectedMethodKind}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ) =>
                                                        handleMethodKindChange(
                                                            e.target.value,
                                                            selectedMethodType
                                                        )
                                                    }
                                                    className={
                                                        selectedMethodKind ===
                                                        'Выбрать'
                                                            ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                            : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                    }
                                                >
                                                    {methodKindOptions.map(
                                                        (option) =>
                                                            option.value ===
                                                            'Выбрать' ? (
                                                                <option
                                                                    disabled
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Вид типового способа */}
                                        <div className="flex flex-row w-full gap-5 ">
                                            <div
                                                className={
                                                    selectedTypicalKind ===
                                                        '' ||
                                                    selectedMethodType ===
                                                        'Иной'
                                                        ? 'flex-col space-y-1 w-[320px] hidden'
                                                        : 'flex flex-col space-y-1 w-[320px]'
                                                }
                                            >
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Вид типового способа
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        value={
                                                            selectedTypicalKind
                                                        }
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLSelectElement>
                                                        ) =>
                                                            handleTypicalKindChange(
                                                                e.target.value,
                                                                selectedMethodKind
                                                            )
                                                        }
                                                        className={
                                                            selectedTypicalKind ===
                                                            'Выбрать'
                                                                ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                        }
                                                    >
                                                        {selectedMethodKind ===
                                                        'Закрытый'
                                                            ? typicalKindOptions
                                                                  .slice(0, 5)
                                                                  .map(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          'Выбрать' ? (
                                                                              <option
                                                                                  disabled
                                                                                  key={
                                                                                      option.value
                                                                                  }
                                                                                  value={
                                                                                      option.value
                                                                                  }
                                                                                  className={
                                                                                      option.optionStyle
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      option.value
                                                                                  }
                                                                              </option>
                                                                          ) : (
                                                                              <option
                                                                                  key={
                                                                                      option.value
                                                                                  }
                                                                                  value={
                                                                                      option.value
                                                                                  }
                                                                                  className={
                                                                                      option.optionStyle
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      option.value
                                                                                  }
                                                                              </option>
                                                                          )
                                                                  )
                                                            : typicalKindOptions.map(
                                                                  (option) =>
                                                                      option.value ===
                                                                      'Выбрать' ? (
                                                                          <option
                                                                              disabled
                                                                              key={
                                                                                  option.value
                                                                              }
                                                                              value={
                                                                                  option.value
                                                                              }
                                                                              className={
                                                                                  option.optionStyle
                                                                              }
                                                                          >
                                                                              {
                                                                                  option.value
                                                                              }
                                                                          </option>
                                                                      ) : (
                                                                          <option
                                                                              key={
                                                                                  option.value
                                                                              }
                                                                              value={
                                                                                  option.value
                                                                              }
                                                                              className={
                                                                                  option.optionStyle
                                                                              }
                                                                          >
                                                                              {
                                                                                  option.value
                                                                              }
                                                                          </option>
                                                                      )
                                                              )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    selectedCompetitiveType ===
                                                    ''
                                                        ? 'flex-col space-y-1 w-[200px] hidden'
                                                        : 'flex flex-col space-y-1 w-[200px]'
                                                }
                                            >
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Тип конкурентности
                                                    </label>
                                                </div>
                                                <div>
                                                    {selectedMethodType ===
                                                        'Иной' &&
                                                    selectedMethodKind ===
                                                        'Закрытый' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedCompetitiveType
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : selectedMethodType ===
                                                      'Типовой' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedCompetitiveType
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (
                                                        <select
                                                            value={
                                                                selectedCompetitiveType
                                                            }
                                                            onChange={
                                                                handleCompetitiveType
                                                            }
                                                            className={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                    : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                            }
                                                        >
                                                            {competitiveTypeOptions.map(
                                                                (option) =>
                                                                    option.value ===
                                                                    'Выбрать' ? (
                                                                        <option
                                                                            disabled
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    ) : (
                                                                        <option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Форма проведения */}
                                        <div
                                            className={
                                                selectedMethodForm === ''
                                                    ? 'flex-col space-y-1 w-[200px] hidden'
                                                    : 'flex flex-col space-y-1 w-[200px]'
                                            }
                                        >
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Форма проведения
                                                </label>
                                            </div>
                                            <div>
                                                <div>
                                                    {selectedMethodType ===
                                                        'Иной' &&
                                                    selectedMethodKind ===
                                                        'Закрытый' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedMethodForm
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (selectedTypicalKind ===
                                                          'Запрос предложений' ||
                                                          selectedTypicalKind ===
                                                              'Запрос котировок' ||
                                                          selectedMethodKind ===
                                                              'Закрытый') &&
                                                      selectedMethodType ===
                                                          'Типовой' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedMethodForm
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (
                                                        <select
                                                            value={
                                                                selectedMethodForm
                                                            }
                                                            onChange={
                                                                handleMethodForm
                                                            }
                                                            className={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                    : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                            }
                                                        >
                                                            {methodFormOptions.map(
                                                                (option) =>
                                                                    option.value ===
                                                                    'Выбрать' ? (
                                                                        <option
                                                                            disabled
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    ) : (
                                                                        <option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Общие настройки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Общие настройки</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Закупка, участниками которой являются только субъекты малого и среднего предпринимательства */}
                                        <div>
                                            <label className="flex flex-row items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    className="peer appearance-none"
                                                />
                                                <div
                                                    className="
                                                                shrink-0

                                                                relative
                                                                w-11
                                                                h-6
                                                                bg-gray-200
                                                                dark:border-gray-600
                                                                dark:bg-gray-700
                                                                rounded-full
                                                                
                                                                peer
                                                                peer-focus:ring-1
                                                                peer-focus:ring-blue-300
                                                                dark:peer-focus:ring-blue-800
                                                                peer-checked:after:translate-x-full
                                                                peer-checked:after:border-white
                                                                peer-checked:bg-blue-600
                                                                
                                                                after:content-['']
                                                                after:absolute
                                                                after:top-0.5
                                                                after:start-[2px]
                                                                after:bg-white
                                                                after:border-gray-300
                                                                after:border
                                                                after:rounded-full
                                                                after:h-5
                                                                after:w-5
                                                                after:transition-all
                                                                "
                                                ></div>
                                                <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                                    Закупка, участниками которой
                                                    являются только субъекты
                                                    малого и среднего
                                                    предпринимательства
                                                </span>
                                            </label>
                                        </div>

                                        {/* Максимальная сумма НМЦД по способу закупки (в руб.) */}
                                        <div>
                                            <label className="flex flex-row items-center cursor-pointer pr-5">
                                                <input
                                                    type="checkbox"
                                                    className="peer appearance-none"
                                                />
                                                <div
                                                    className="
                                                                shrink-0

                                                                relative
                                                                w-11
                                                                h-6
                                                                bg-gray-200
                                                                dark:border-gray-600
                                                                dark:bg-gray-700
                                                                rounded-full
                                                                
                                                                peer
                                                                peer-focus:ring-1
                                                                peer-focus:ring-blue-300
                                                                dark:peer-focus:ring-blue-800
                                                                peer-checked:after:translate-x-full
                                                                peer-checked:after:border-white
                                                                peer-checked:bg-blue-600
                                                                
                                                                after:content-['']
                                                                after:absolute
                                                                after:top-0.5
                                                                after:start-[2px]
                                                                after:bg-white
                                                                after:border-gray-300
                                                                after:border
                                                                after:rounded-full
                                                                after:h-5
                                                                after:w-5
                                                                after:transition-all
                                                                "
                                                ></div>

                                                <div className="flex flex-col space-y-1 w-full">
                                                    <span className="ml-5 text-[12.8px] text-[#8A8A8A]">
                                                        Максимальная сумма НМЦД
                                                        по способу закупки (в
                                                        руб.)
                                                    </span>
                                                    <input
                                                        className="ml-5 w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                    />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* buttons */}
                        <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                            <button className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                                Закрыть
                            </button>
                            <button className="flex h-7 bg-[#187A33] w-[120px] rounded-lg text-[12.8px] justify-center items-center">
                                Сохранить
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                'Viewing'
            )}
        </>
    )
}

export default OrgPurchaseMethodViewOrEdit
