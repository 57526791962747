import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

const MenuIconItem: React.FC<{ link: string; icon: ReactElement }> = ({
    link,
    icon,
}) => {
    return (
        <li>
            <Link
                to={link}
                className="flex w-12 h-12 rounded-lg items-center p-1 hover:bg-[#464447] "
            >
                <div className="flex w-full aspect-square rounded-full bg-[#262427] items-center justify-center text-zinc-500">
                    {icon}
                </div>
            </Link>
        </li>
    )
}

export default MenuIconItem
