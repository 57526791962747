import React from 'react'
import { useNavigate } from 'react-router-dom'

const OrgHandbookListPage: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Реестр справочников
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit ">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg"></div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-14 place-items-center text-center">
                                    №
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Наименование справочника
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-dvh bg-[#1D1C1E]">
                                {/* Вывод пользователей */}
                                <>
                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        onClick={() =>
                                            navigate('/hub/org/handbook/okpd2')
                                        }
                                    >
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            1
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            ОКПД 2 - Общероссийский
                                            классификатор продукции по видам
                                            экономической деятельности
                                        </div>
                                    </div>

                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        onClick={() =>
                                            navigate('/hub/org/handbook/okved2')
                                        }
                                    >
                                        <div className="grid h-10 w-14 place-items-center text-center text-[#8A8A8A]">
                                            2
                                        </div>
                                        <div className="grid h-10 flex-1 place-items-center justify-items-start text-left px-2 py-2">
                                            ОКВЭД 2 - Общероссийский
                                            классификатор видов экономической
                                            деятельности
                                        </div>
                                    </div>
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgHandbookListPage
