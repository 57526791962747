import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/auth'
import { Navigate } from 'react-router-dom'
import ColumnChart from '../Hub/components/DashboardCharts/ColumnChart'
import LineChart from '../Hub/components/DashboardCharts/LineChart'
import PieChartPlanCount from './components/DashboardCharts/PieChartPlanCount'
import PieChartPlanSumm from './components/DashboardCharts/PieChartPlanSumm'
import PieChartPlanCountMSP from './components/DashboardCharts/PieChartPlanCountMSP'
import PieChartPlanSummMSP from './components/DashboardCharts/PieChartPlanSummMSP'
import PieChartPlanCountGWS from './components/DashboardCharts/PieChartPlanCountGWS'
import PieChartPlanSummGWS from './components/DashboardCharts/PieChartPlanSummGWS'
import PieChartPlanCountForm from './components/DashboardCharts/PieChartPlanCountForm'
import PieChartPlanSummType from './components/DashboardCharts/PieChartPlanSummType'
import TableChartFHD from './components/DashboardCharts/TableChartFHD'
import TableChartPurchaseMethod from './components/DashboardCharts/TableChartPurchaseMethod'
import TableChartPurchaseStruct from './components/DashboardCharts/TableChartPurchaseStruct'
import PositionsStageCount from './components/DashboardCharts/PositionsStageCount'

import { Plan } from './Plans/types'

type ApiResponse = {
    message: Plan[]
}

const HubPage: React.FC = () => {
    const [guid, setGuid] = useState<string>('')
    const [registrationNumber, setRegistrationNumber] = useState<number>(0)
    const [availableYears, setAvailableYears] = useState<number[]>([])
    const [selectedYear, setSelectedYear] = useState<string | undefined>(
        'Нет данных'
    )
    const { user, logout } = useAuth()

    const fetchPlans = async (initialYear?: string) => {
        try {
            const response = await fetch(
                'https://itzakupki.ru/api/backend/v1/plans',
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`)
            }
            const data: ApiResponse = await response.json()
            const plans: Plan[] = data.message

            if (!plans || plans.length === 0) {
                setGuid('Нет данных')
                setAvailableYears([])
                setSelectedYear('Нет данных')
                return
            }

            // Извлечение уникальных годов из start_date
            const years = Array.from(
                new Set(
                    plans
                        .map((plan) => plan.start_date?.split('-')[0])
                        .filter(Boolean)
                )
            )
                .map(Number)
                .sort((a, b) => b - a)

            setAvailableYears(years)

            // Выбор года
            const currentYear = new Date().getFullYear()
            let defaultYear = initialYear

            if (!initialYear) {
                if (years.includes(currentYear)) {
                    defaultYear = currentYear.toString()
                } else if (years.includes(currentYear - 1)) {
                    defaultYear = (currentYear - 1).toString()
                } else {
                    defaultYear = 'Нет данных'
                }
            }

            setSelectedYear(defaultYear)

            // Найти план для выбранного года
            const planForYear = plans.find((plan) =>
                plan.start_date?.startsWith(defaultYear || '')
            )
            if (planForYear) {
                setGuid(planForYear.guid || 'Нет данных')
                setRegistrationNumber(planForYear.registration_number || 0)
            } else {
                setGuid('Нет данных')
                setRegistrationNumber(0)
            }
        } catch (error) {
            console.error('Error fetching plans:', error)
            setGuid('Нет данных')
            setAvailableYears([])
            setSelectedYear('Нет данных')
        }
    }

    useEffect(() => {
        fetchPlans()
    }, [])

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const year = e.target.value
        setSelectedYear(year)
        fetchPlans(year) // Передаём выбранный год в fetchPlans
    }

    const handleLogout = () => {
        fetch('https://itzakupki.ru/api/auth/v1/users/logout/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((r) => {
            if (r.ok) {
                logout()
            }
        })
    }

    if (!user) {
        return <Navigate to="/auth" />
    }

    console.log('gyid= ' + guid)

    return (
        <div className="flex flex-col w-full h-screen bg-[#181818]">
            <div className="flex flex-row w-full h-24 justify-center items-center bg-[#000000]">
                <button
                    onClick={handleLogout}
                    className="text-[#fff] w-fit h-fit bg-slate-800 py-1 px-3 rounded absolute top-8 left-52"
                >
                    Выход
                </button>
                <PositionsStageCount plan_guid={guid || 'Нет данных'} />
            </div>

            <div className="flex flex-row w-full h-20 justify-center items-center bg-[#111111]">
                <div className="flex flex-row w-[1720px] h-full">
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Период планирования
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            <select
                                className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {availableYears.length === 0 ? (
                                    <option value="Нет данных">
                                        Нет данных...
                                    </option>
                                ) : (
                                    availableYears.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            План закупки
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            {registrationNumber == 0
                                ? 'Нет данных'
                                : registrationNumber}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Количество позиций
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            89
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Запланировано закупок
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            8 454 112,02 ₽
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Запланировано закупок у МСП
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            7 300 112,02 ₽ (84%)
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full h-[calc(100vh_-_176px)] justify-start items-center bg-[#181818] overflow-y-auto overflow-x-hidden">
                <div className="flex flex-col w-[1720px] bg-[#181818] justify-center items-center space-y-5 py-5">
                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanCount
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                                    Загрузка...
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanSumm
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                                    Загрузка...
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanCountMSP
                                initialIndex={1}
                                totalIndex={0}
                            />
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanSummMSP
                                initialIndex={1}
                                totalIndex={0}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanCountGWS initialIndex={0} />
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanSummGWS initialIndex={0} />
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanSummType initialIndex={0} />
                        </div>
                        <div className="flex flex-col w-[404px] h-60 bg-[#262427] rounded-lg p-3">
                            <PieChartPlanCountForm initialIndex={0} />
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[829px] h-[380px] bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <ColumnChart plan_guid={guid || 'Нет данных'} />
                            ) : (
                                <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                                    Загрузка...
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[829px] h-[380px] bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <LineChart plan_guid={guid || 'Нет данных'} />
                            ) : (
                                <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                                    Загрузка...
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[546px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartFHD plan_guid={guid || 'Нет данных'} />
                        </div>

                        <div className="flex flex-col w-[546px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartPurchaseMethod
                                plan_guid={guid || 'Нет данных'}
                            />
                        </div>

                        <div className="flex flex-col w-[546px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartPurchaseStruct
                                plan_guid={guid || 'Нет данных'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HubPage
