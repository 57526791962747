import React from 'react'

interface NumberFormatProps {
    value: number
}

const NumberFormat: React.FC<NumberFormatProps> = ({ value }) => {
    const formattedValue = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value)

    return <>{formattedValue}</>
}

export default NumberFormat
