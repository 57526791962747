import React from 'react'

type Props = {
    positionCount: number
    sum: number
}

const PlanPositionsHeader: React.FC<Props> = ({ positionCount, sum }) => {
    return (
        <div className=" h-24 mx-6 flex flex-row gap-x-5 ">
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">Реестровый номер</div>
                <div className="text-xs text-white ">{'516516'}</div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Период планирования
                </div>
                <div className="text-xs text-white ">2024</div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Количество позиций
                </div>
                <div className="text-xs text-white ">{positionCount}</div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок
                </div>
                <div className="text-xs text-white ">
                    {Number(sum).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                    }) + ' ₽'}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок у МСП
                </div>
                <div className="text-xs text-white ">0,00 ₽ (0%)</div>
            </div>
            <div className="w-60 my-auto content-center ">
                <div className="text-xs text-[#8A8A8A] ">
                    Закупки исключаемые у МСП
                </div>
                <div className="text-xs text-white ">0,00 ₽</div>
            </div>
        </div>
    )
}

export default PlanPositionsHeader
