export enum PlanPositionStage {
    EDIT = 'Редактирование',
    READY_FOR_PLACEMENT = 'Подготовка к размещению',
    SENT_TO_EIS = 'Отправление в ЕИС',
    PURCHASE_PLAN_IN_EIS = 'План закупки в ЕИС',
}

export type PlanPosition = {
    guid: string
    id: number
    ordinal_number: number
    contract_subject: string
    plan_item_customer: PlanItemCustomer
    minimum_requirements: string | null
    contract_end_date: string // Date string in ISO format
    modification_description: string | null
    status: string
    cancellation_reason: string | null
    other_changes: boolean | null
    long_term: boolean
    shared: boolean
    initial_position_guid: string | null
    initial_plan_guid: string | null
    long_term_volumes: LongTermVolumes | null
    long_term_smb_volumes: LongTermVolumes | null
    parent_id: string
    okato: string
    region: string
    is_general_address: boolean
    maximum_contract_price: string
    currency_digital_code: number
    order_pricing: string | null
    exchange_rate: number | null
    exchange_rate_date: string | null // Date string in ISO format
    maximum_contract_price_rub: any | null // Replace 'any' with the actual type if known
    purchase_planned_date: string | null // Date string in ISO format
    purchase_period_year: number | null
    purchase_period_quarter: number | null
    purchase_period_month: string | null
    purchase_method_code: number | null
    purchase_method_name: string
    hide_purchase_plan_item: boolean
    is_electronic: boolean
    is_purchase_ignored: boolean
    purchase_сategory: any | null // Replace 'any' with the actual type if known
    innovation_equivalent: boolean
    planned_after_second_year: boolean
    maximum_contract_price_from_budget: boolean
    position_financial_provision_volumes: any | null // Replace 'any' with the actual type if known
    organization_guid: string
    plan_guid: string
    last_synced_time: string // Date string in ISO format
    sys_created_at: string // Date string in ISO format
    sys_updated_at: string // Date string in ISO format
    guid_eis: string | null
    position_edition: number | null
    position_stage: string | null
    position_status: string | null
}

export type PlanItemCustomer = {
    iko: string
    inn: number
    kpp: number
    ogrn: number
    okfs: number
    okpo: number
    email: string | null
    okato: string
    okopf: number
    full_name: string
    okfs_name: string
    okopf_name: string
    short_name: string
    legal_address: string
    postal_address: string
}

export type LongTermVolumes = {
    volume: number
    details: Details
    currency: Currency
}

export type Details = {
    items: ItemDetail[]
}

export type ItemDetail = {
    summ: number
    year: number
}

export type Currency = {
    code: string
    name: string
    digital_code: number
}
