export const dateToString = (date: Date): string => {
    const month = date.getMonth() + 1
    const day = date.getDate()

    const addLeadingZero = (value: number): string => {
        return (value < 10 ? '0' : '') + value
    }

    return (
        addLeadingZero(day) +
        '.' +
        addLeadingZero(month) +
        '.' +
        date.getFullYear()
    )
}
