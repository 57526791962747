import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

type ApiResponse = {
    detail: string
    access_token: string
}

const RestoreForm: React.FC = () => {
    const navigation = useNavigate()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isValid, setValid] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)

        fetch('https://itzakupki.ru/api/auth/v1/users/restore/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Произошла ошибка. Попробуйте еще раз.')
                }
            })
            .then((result: ApiResponse) => {
                navigation('/', { replace: true })
            })
            .catch((serverError) => {
                setError(serverError.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValid(isEmail(email))
    }, [email])

    return (
        <>
            <h5 className="mb-5 text-lg text-center text-white">
                Восстановление пароля
            </h5>
            <form method="post" onSubmit={handleSubmit}>
                <fieldset disabled={isLoading}>
                    <div className="flex flex-col mb-4">
                        <label className="mb-1 text-sm text-zinc-500">
                            Адрес электронной почты
                        </label>
                        <input
                            name="email"
                            type="email"
                            className="p-2 rounded-md border-0 text-sm bg-neutral-900 text-white"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                        />
                    </div>
                    <button
                        className="w-full p-2 bg-fuchsia-600 rounded-full shadow-md text-sm text-white disabled:bg-fuchsia-900 disabled:text-gray-400"
                        disabled={!isValid}
                    >
                        Восстановить
                    </button>
                </fieldset>
            </form>
            {error && (
                <p className="mt-2 mb-0 text-sm text-red-600" role="alert">
                    {error}
                </p>
            )}
        </>
    )
}

export default RestoreForm
