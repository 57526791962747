import React, { useState } from 'react'
import Section from '../../../../../../components/Section'

const PurchaseChangeInfo: React.FC = () => {
    return (
        <Section title="Информация об измене позиции плана закупки">
            <div className="flex flex-col">
                <label className="mb-1 text-sm text-zinc-500">Примечание</label>
                <textarea
                    className="p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={1000}
                    rows={2}
                />
            </div>
        </Section>
    )
}

export default PurchaseChangeInfo
