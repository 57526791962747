import React from 'react'
import { NumericFormat } from 'react-number-format'

type Props = {
    value?: number
    className?: string
    disabled?: boolean
    onChange?: (value: number) => void
}

const MoneyInput: React.FC<Props> = ({
    value,
    className = '',
    disabled = false,
    onChange = () => undefined,
}) => {
    return (
        <NumericFormat
            value={value}
            className={' text-right ' + className}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            thousandSeparator=" "
            placeholder="0,00"
            disabled={disabled}
            onValueChange={(v) => onChange(v.floatValue as number)}
        />
    )
}

export default MoneyInput
