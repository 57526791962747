import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'

const HubLayout: React.FC = () => {
    const { user } = useAuth()
    if (!user) {
        return <Navigate to="/auth" />
    }

    return <Outlet />
}

export default HubLayout
