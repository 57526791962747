import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const OrgListFxdPage: React.FC = () => {
    const [popup, setPopup] = useState(false)
    const navigate = useNavigate()

    const handlePopup = () => {
        setPopup(!popup)
    }

    return (
        <div className="flex flex-col text-white min-h-screen">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-full h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Коды и статьи финансово-хозяйственной деятельности
                            (ФХД)
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center h-[calc(100vh_-_120px)] p-5">
                <div className="flex flex-col w-[1260px] space-y-10">
                    {/* Общая информация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Общие сведения</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Дата размещения, Период действия */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[200px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Дата утверждения
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="date"
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col h-full bg-[#262427] rounded-lg p-5 space-y-5 overflow-y-auto">
                        {/* table */}
                        <div className="flex flex-col h-full bg-[#242225] rounded">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t place-items-center">
                                <button className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="filter"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                        ></path>
                                    </svg>
                                </button>

                                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                                    Позиций <br />5
                                </div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-12 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="grid w-7 place-items-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-14 justify-center items-center text-center">
                                    Коды
                                </div>
                                <div className="flex flex-col w-28 justify-center items-center text-center">
                                    Статьи
                                </div>
                                <div className="grid flex-1 place-items-center text-center">
                                    Наименования финансово-хозяйственной
                                    деятельности (ФХД)
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 my-1 h-full">
                                {/* Вывод ТРУ */}
                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row cursor-pointer gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded">
                                    <div className="grid w-7 place-items-center items-center text-center text-[#8A8A8A]">
                                        1
                                    </div>
                                    <div className="grid w-14 place-items-center items-center px-2">
                                        123
                                    </div>
                                    <div className="grid w-28 place-items-center items-center px-2">
                                        12.31.4
                                    </div>
                                    <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                        Приобретение запчастей для автомашин
                                    </div>
                                    <div className="grid w-10 place-items-center text-center">
                                        <button className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]">
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10 space-x-2">
                                <button
                                    className="rounded-full px-4 h-6 text-[12.8px] bg-[#494849] hover:bg-[#656365]"
                                    onClick={handlePopup}
                                >
                                    + Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                <div className="flex items-center space-x-5">
                    <button
                        className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center"
                        onClick={() => navigate('/hub/org/lists')}
                    >
                        Закрыть
                    </button>
                    <button className="flex h-7 bg-[#187A33] w-[180px] rounded-lg text-[12.8px] justify-center items-center">
                        Сохранить и закрыть
                    </button>
                </div>
            </div>

            {popup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handlePopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2>Добавить статью ФХД</h2>
                        <div className="flex flex-col mt-5 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Код
                            </label>
                            <input
                                type="text"
                                className="w-[200px] h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            />
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Статья
                            </label>
                            <input
                                type="text"
                                className="w-[200px] h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            />
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Наименования финансово-хозяйственной
                                деятельности (ФХД)
                            </label>
                            <input
                                type="text"
                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            />
                        </div>
                        <div className="mt-5 text-right">
                            <button className="button" onClick={handlePopup}>
                                Отмена
                            </button>
                            <button className="button ml-2">Сохранить</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgListFxdPage
