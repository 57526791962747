import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './components/NavBar'

const HubLayoutInner: React.FC = () => {
    return (
        <div className="h-full flex flex-row">
            <NavBar />
            <main className="flex flex-1 flex-col">
                <Outlet />
            </main>
        </div>
    )
}

export default HubLayoutInner
