import React from 'react'
import './index.css'
import { PlanPositionStage } from '../../types'

type Props = {
    stage: PlanPositionStage
    onTabChange: (stage: PlanPositionStage) => void
}

const PlanPositionsTabs: React.FC<Props> = ({ stage, onTabChange }) => {
    return (
        <div className="flex flex-row cursor-pointer">
            <div className="w-5"></div>
            {Object.values(PlanPositionStage).map((item, index) => {
                return (
                    <span
                        key={index}
                        className={
                            stage == item
                                ? 'plan-position-tab-active'
                                : 'plan-position-tab-inactive'
                        }
                        onClick={() => onTabChange(item)}
                    >
                        {item}
                    </span>
                )
            })}
        </div>
    )
}

export default PlanPositionsTabs
